import React from "react"
import { Link } from "gatsby"
import DispayLeafletMap from "../../components/displaymap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "nuka-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import LeafletMap from "../../components/leafletmap"
import { useSelector } from "react-redux"

import groceriesIcon from "../../images/icon/paper-bag.svg"

export const AFSAMEPage = props => {
  const data = useStaticQuery(graphql`
    query imagesAFSAME {
      AFSAME_1: file(relativePath: { eq: "producteur/AFSAME_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      AFSAME_2: file(relativePath: { eq: "producteur/AFSAME_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      AFSAME_3: file(relativePath: { eq: "producteur/AFSAME_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const panier = useSelector(state => state.panierR)

  const latitude = 47.446979
  const longitude = 5.597972

  const state = {
    markers: [{ key: "marker1", position: [latitude, longitude] }],
    latlng: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 9,
  }

  return (
    <Layout>
      <SEO title="Ferme Bio de AFSAME" />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-1">AFSAME - Les jardins bio des Monts de Gy</h1>
          </div>
        </div>
      </section>

      <section className="pt-5 mt-5">
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-one-third">
              <h2 className="almond is-size-3 has-text-centered">
                Gy (70700)
              </h2>
              {typeof window !== "undefined" && (
                <DispayLeafletMap
                  style={{ height: "600px" }}
                  markers={state.markers}
                  center={state.latlng}
                  zoom={state.zoom}
                />
              )}
            </div>
            <div className="column">
              <div>
                <Carousel
                  autoplay={true}
                  autoplayInterval={2500}
                  transitionMode={"fade"}
                  wrapAround={true}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronLeft}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronRight}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                >
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.AFSAME_3.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.AFSAME_2.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.AFSAME_1.childImageSharp.fluid}
                  />
                </Carousel>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column ">
              <div>
                <section className="m-2 has-text-justified is-size-4">
                  <div className="box" style={{ background: "#ffff76" }}>
                    <p className="is-4">
                      L'entreprise adaptée Les Jardins Bio des Monts de Gy (Haute-Saône) a été créée par l’Association Franco Suisse d’Action Médico Educative (AFSAME) qui gère l'IMP de Choye.
                    </p>
                    <p className="is-4">
                      L'entreprise joue un rôle d'insertion pour des travailleurs en situation de handicap et de difficultés sociales.
                    </p>
                    <p className="is-4">
                      Ces emplois durables œuvrent pour une <strong>production maraîchère biologique plein champs et sous serre</strong>, pour une production toute l'année d'une grande variété de légumes de saison.
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="column is-half has-text-centered">
              {(panier.zone === null && panier.pickupPoint === null) || panier.zone !== 16 ? (
                <Link to="#selectPoint">
                  {" "}
                  <button className="button is-warning mt-4">
                    Sélectionnez un point de retrait pour voir les produits
                  </button>
                </Link>
              ) : (
                <Link to={`/producteur/16-afsame/produits`}>
                  <button className="button is-success is-light is-large mt-4 dreamland is-size-3">
                    <img
                      src={groceriesIcon}
                      style={{
                        width: 40,
                        height: 40,
                        position: "relative",
                        top: 12,
                        marginRight: 15,
                      }}
                      alt="Produits"
                    />
                    Voir les produits
                  </button>
                </Link>
              )}
              <span
                className="mt-5 ml-4 almond is-size-3"
                style={{ display: "block" }}
              >
                Ce producteur livre aux points de dépôt suivants :
              </span>
              {typeof window !== "undefined" && (
                <section id="selectPoint" className="mt-4 producer">
                  <LeafletMap producteur={[16]} />
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AFSAMEPage
